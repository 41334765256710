import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../UserContext';
import { axios } from '../axios';
import { BrowserRouter, Routes, Route, Link, useNavigate } from "react-router-dom";
import M from 'materialize-css'
import Pedidos from './Pedidos/pedidos.js'
import OrderStatus from './Pedidos/orderStatus.js'
import { Catalogo, NewProduct, EditProduct } from './Inventarios'
import PerfilEmpresa from './Configuracion/CompanyInformation'
import Mensajes from './Mensajes'
import MiTienda from './Configuracion/MiTienda'
import Cotizaciones from './Cotizaciones'
import Cotizacion from './Cotizaciones/cotizacion'
import Visitas from './Visitas'

const Dashboard = () => {

	const navigate = useNavigate();
	const { user, setAuth, setUser } = useContext(UserContext);
	const [loader, setLoader] = useState(true)
	const [conversations, setConversations] = useState([])
	
	useEffect(()=>{
		axios.get('/api/user')
		.then((response)=>{
			setUser(response.data)	
			setLoader(false)
			M.AutoInit()

			/**
			  * Mensajes
			  */
			axios.get('/api/message/my_messages')
			.then((r)=>{
				setConversations(r.data.conversations)
			})
			.catch(r=>console.log(r))
		})
		.catch((e)=>console.log('error',e))

		axios.get('/api/my_catalogo_count')
		.then((response)=>{
			if(response.data.my_catalogo_count===0){
				setTimeout(()=>{
					M.Modal.init(document.querySelectorAll('#modal-important'), {});
					let instance = M.Modal.getInstance(document.querySelector('#modal-important'));
					instance.open()
				},2000)
			}
		})
		.catch((e)=>console.log('error',e))
	}, [])

	useEffect(()=>{
		M.AutoInit()
	},[])
	
	const _salir=()=>{
		axios.get('/api/logout')
		.then(r=>{
			localStorage.setItem('access_token', '');
			setAuth(false)
			navigate('/login')
		})
	}
	
	const Hola=()=>{
		return (
			<div className="card-panel">
				<h4>BIENVENIDO SELLER {user.name.toUpperCase()}</h4>
			</div>
		)
	}

	if(loader) return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)

	return (
		<>
		<div id="modal-important" className="modal">
			<div className="modal-content">
				<h4>Important</h4>
				<p>Please enter your company information in the SETTINGS option under MY DATA. Once completed, proceed to upload a product.</p>
			</div>
			<div className="modal-footer">
				<a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
			</div>
		</div>

		<div className="row">
			<div className="col s2" id="menu" style={{width: '250px'}}>
				<ul id="slide-out" className="sidenav collapsible sidenav-fixed" style={{width: '250px',backgroundColor:'#BE1D2C', border:0}}>
					<li className='center' style={{padding:'20px'}}>
						<img width='150' src={axios.defaults.baseURL+'/logo.png'} alt='recurso' style={{objectFit:'scale-down',width:'100%',boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}/>
					</li>
					<li>
						<Link to='/' className='collapsible-header' style={{display:'flex', alignItems:'center', color:'white'}}>
							<i className="material-icons white-text">home</i> Home
						</Link>
					</li>
					<li>
						<div className="collapsible-header menu-title white-text">
							<i className="material-icons">library_books</i> Visits
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/visitas" className="waves-effect"><i className="material-icons">loyalty</i>Dairy</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<Link to='/mensajes' className='collapsible-header' style={{display:'flex', alignItems:'center', color:'white'}}>
							<i className="material-icons white-text">chat</i> Chat
							{conversations.flatMap(c=>c.messages).filter((m)=>!m.visto).reduce((a,b)=>a+=b.sender_name===user.name?0:1, 0)>0&&
							<label style={{background:'red', borderRadius:5, padding:5, color:'white', fontWeight:'bold', lineHeight:'normal', fontSize:9, margin:5}}>{conversations.flatMap(c=>c.messages).filter((m)=>!m.visto).reduce((a,b)=>a+=b.sender_name===user.name?0:1, 0)}</label>
							}
						</Link>
					</li>
					<li>
						<div className="collapsible-header menu-title white-text">
							<i className="material-icons">library_books</i> Inventory
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/catalogo" className="waves-effect"><i className="material-icons">loyalty</i>My catalog</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header white-text">
							<i className="material-icons">assignment_turned_in</i> Quotes
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/cotizaciones" className="waves-effect"><i className="material-icons">storage</i>Quotes list</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header white-text">
							<i className="material-icons">local_atm</i> Orders
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/listapedidos" className="waves-effect"><i className="material-icons">storage</i>In progress</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header white-text">
							<i className="material-icons">settings_applications</i> Settings
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
							<ul>
								{/*<li><a href="#!" className="subheader">Configuración</a></li>*/}
								<li><Link to="/mis-datos" className="waves-effect"><i className="material-icons">store</i>My data</Link></li>

								{/**
								   * se oculta porque se deshabilito la vista de slider en la tienda del proovedor, esta opcion solo se usa para configurar imagenes
								   * y banners, fue solicitado una nueva vista en /proveedor?name=, que no incluye slider, el slider si fue hecho en kangoru-shop-web(tambien permanece oculta)
								   *
								<li><Link to="/mi-tienda" className="waves-effect"><i className="material-icons">store</i>My store</Link></li>
								 */}
							</ul>
						</div>
					</li>
					<li>
						<a href="#!" onClick={_salir} className="waves-effect white-text" style={{padding:0, margin:0, display:'block', alignItems:'center'}}>
                                                        <i className="material-icons white-text" style={{padding:0, margin:'0 10px 0 0',}}>exit_to_app</i>Exit
                                                </a>
					</li>
				</ul>
				<a href="#!" data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a>
			</div>
			<div className="col s10">
			<Routes>
				<Route path="/" element={<Hola />} />
				<Route path="/orderStatus" element={<OrderStatus />} />
				<Route path="/catalogo" element={<Catalogo />} />
				<Route path="/listapedidos" element={<Pedidos />} />
				{/*e-commerce*/}
				<Route path="/altaproducto" element={<NewProduct />} />
				<Route path="/catalogo/producto/:catalogo_id" element={<EditProduct />} />
				<Route path="/mis-datos" element={<PerfilEmpresa />} />
				{/*Mensajes*/}
				<Route path="/mensajes" element={<Mensajes />} />
				{/*Cotizaciones*/}
				<Route path="/cotizaciones" element={<Cotizaciones />} />
				<Route path="/cotizacion/:cotizacion_id" element={<Cotizacion />} />
				{/*Configuracion*/}
				<Route path="/mi-tienda" element={<MiTienda />} />
				{/*Visitas*/}
				<Route path="/visitas" element={<Visitas />} />
			</Routes>
			</div>
		</div>
		</>
	)
}

export default Dashboard
