import React, { useState, useEffect } from 'react';
import { axios } from '../../../axios'
import M from 'materialize-css'

const Step3ContactPerson = ({percent_calc}) => {

	const [data, setData] = useState({contact_persons:[{name:'', position:'', telephone:'', email:'', whatsapp:'', wechat:''}, {name:'', position:'', telephone:'', email:'', whatsapp:'', wechat:''}, {name:'', position:'', telephone:'', email:'', whatsapp:'', wechat:''}]})

	useEffect(()=>{
		axios.get('/api/seller/get_seller')
		.then(r=>{
			setData({contact_persons:r.data.contact_persons})
		})
	},[])

	const _send = (e) => {
		e.preventDefault()
		axios.post('/api/seller/seller_contact_person', data)
		.then(r=>{
			setData({contact_persons:r.data.contact_persons})
			M.toast({html:'Updated!'})
			percent_calc(r.data.states)
			/**
			  * Next tab
			  */
			const tabs = document.querySelector(".tabs")
			const instance = M.Tabs.getInstance(tabs)
			instance.select('step5')
			window.scrollTo(0, 0)
		})
	}

	return (
		<form onSubmit={_send}>
			<div className="row">
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Contact 1</div>
				</div>
			</div>

			<div className="row">
				<div className="input-field col s6">
					<input value={data.contact_persons[0].name||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===0?{...v, name:e.target.value}:v)})} id="contact1_name" type="text" className="validate"/>
					<label htmlFor="contact1_name">Name</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[0].position||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===0?{...v, position:e.target.value}:v)})} id="contact1_position" type="text" className="validate"/>
					<label htmlFor="contact1_position">Position</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[0].telephone||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===0?{...v, telephone:e.target.value}:v)})} id="contact1_telephone" type="text" className="validate"/>
					<label htmlFor="contact1_telephone">Telephone</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[0].email||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===0?{...v, email:e.target.value}:v)})} id="contact1_email" type="text" className="validate"/>
					<label htmlFor="contact1_email">Email</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[0].whatsapp||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===0?{...v, whatsapp:e.target.value}:v)})} id="contact1_whatsapp" type="text" className="validate"/>
					<label htmlFor="contact1_whatsapp">Whatsapp</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[0].wechat||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===0?{...v, wechat:e.target.value}:v)})} id="contact1_wechat" type="text" className="validate"/>
					<label htmlFor="contact1_wechat">Wechat</label>
				</div>
			</div>

			<div className="row">
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Contact 2</div>
				</div>
			</div>

			<div className="row">
				<div className="input-field col s6">
					<input value={data.contact_persons[1].name||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===1?{...v, name:e.target.value}:v)})} id="contact2_name" type="text" className="validate"/>
					<label htmlFor="contact2_name">Name</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[1].position||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===1?{...v, position:e.target.value}:v)})} id="contact2_position" type="text" className="validate"/>
					<label htmlFor="contact2_position">Position</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[1].telephone||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===1?{...v, telephone:e.target.value}:v)})} id="contact2_telephone" type="text" className="validate"/>
					<label htmlFor="contact2_telephone">Telephone</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[1].email||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===1?{...v, email:e.target.value}:v)})} id="contact2_email" type="text" className="validate"/>
					<label htmlFor="contact2_email">Email</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[1].whatsapp||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===1?{...v, whatsapp:e.target.value}:v)})} id="contact2_whatsapp" type="text" className="validate"/>
					<label htmlFor="contact2_whatsapp">Whatsapp</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[1].wechat||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===1?{...v, wechat:e.target.value}:v)})} id="contact2_wechat" type="text" className="validate"/>
					<label htmlFor="contact2_wechat">Wechat</label>
				</div>
			</div>

			<div className="row">
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Contact 3</div>
				</div>
			</div>

			<div className="row">
				<div className="input-field col s6">
					<input value={data.contact_persons[2].name||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===2?{...v, name:e.target.value}:v)})} id="contact3_name" type="text" className="validate"/>
					<label htmlFor="contact3_name">Name</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[2].position||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===2?{...v, position:e.target.value}:v)})} id="contact3_position" type="text" className="validate"/>
					<label htmlFor="contact3_position">Position</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[2].telephone||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===2?{...v, telephone:e.target.value}:v)})} id="contact3_telephone" type="text" className="validate"/>
					<label htmlFor="contact3_telephone">Telephone</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[2].email||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===2?{...v, email:e.target.value}:v)})} id="contact3_email" type="text" className="validate"/>
					<label htmlFor="contact3_email">Email</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[2].whatsapp||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===2?{...v, whatsapp:e.target.value}:v)})} id="contact3_whatsapp" type="text" className="validate"/>
					<label htmlFor="contact3_whatsapp">Whatsapp</label>
				</div>
				<div className="input-field col s6">
					<input value={data.contact_persons[2].wechat||''} onChange={(e)=>setData({...data, contact_persons:data.contact_persons.map((v, i)=>i===2?{...v, wechat:e.target.value}:v)})} id="contact3_wechat" type="text" className="validate"/>
					<label htmlFor="contact3_wechat">Wechat</label>
				</div>
			</div>
			<button className='btn waves-effect waves-light right black'>Save</button>
		</form>
	)
}

export default Step3ContactPerson
