//import {createBrowserHistory} from 'history';
window.axios = require('axios');
window.axios.defaults.baseURL = 'https://kangoru-inventario-api.asiamart.com.mx';
window.axios.interceptors.response.use(
    function (response) { 
        return response; 
    }, 
    function (error) {
        if (401 === error.response.status) {
            //window.location.replace('login');
           //createBrowserHistory().push('/login');
           //window.location.reload();
           window.location.replace('/login');
        }

	return Promise.reject(error);
    });

window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
export const axios = window.axios;
