import React from 'react'

const Loader = () => {
	return(
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)
}

export default Loader
