import React,{useState,createContext} from 'react';

export const UserContext = createContext();
export const UserProvider = props => {
	const [user,setUser] = useState({});
	const [auth, setAuth] = useState(false);
	const [configs,setConfigs] = useState({config_store:{wizard_config:{}},recolecciones:{"tipo_recoleccion":"","dias":"","hora":"",PickupLoaction:{Contact:{PersonName:"",CompanyName:"",PhoneNumber:""},Address:{StreetLines:["",""],City:"",PostalCode:"",CountryCode:"MX"}}}})
	return (
		<UserContext.Provider value={{user,setUser,auth,setAuth,configs,setConfigs}}>
			{props.children}
		</UserContext.Provider>
	)
}
