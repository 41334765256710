import React, { useState,useEffect } from 'react';
import { axios } from '../../../axios';
import M from 'materialize-css'

const SliderImg = ({img, setImgs, image}) => {

	const [data, setData] = useState({titulo:'',subtitulo:'',descripcion:'',titulo_btn:'',url_btn:' ',img:'',color_txt:'',visible:false, visible_btn:false})
	
	useEffect(() => {
		if(img){
			setData(img)
			//setFlagBtn((img.url_btn)?true:false)
		}
	},[])

	const _updateImageData = () =>{
		
		axios.put('/api/seller/configwebsitesliders',data).then((response)=>{
			setData(response.data)
			//setData({...data,visible:response.data.visible===1?true:false})
			//setData({...data,visible_btn:response.data.visible_btn===1?true:false})
			M.toast({html: 'Saved!'})
		}).catch(e=>alert(e))
		
	}

	const _deleteImg = () =>{
		if(image.length === 1){
			return alert("Debes tener al menos una imagen disponible para el carrusel");
		}
		if(window.confirm("¿Estas seguro de borrar esta imagen?")){
			axios.delete(`/api/seller/configwebsitesliders/${data.id}`).then((r)=>{
				setImgs(image.filter(i=>i.id!==r.data.id))
				M.toast({html: 'Deleted!'})
			}).catch(e=>console.log(e))
		}
	}

	const _imgUpload = (img) =>{
		const fData = new FormData();
		fData.append('id', data.id)
		fData.append("files", img);
		axios.post('/api/seller/imgslider',fData)
		.then(r=>{
			M.toast({html: 'Updated!'})
			setData({...data, img:r.data.img})
		})
		.catch(r=>alert(r))
	}

	return (
		<div className="row card" style={{margin:'2px', padding:'10px', width:'100%',background:'transparent'}}>
			<div className="col m4 card-image" style={{margin:0, padding:'15px 6px',}}>
				<div style={{height:'240px',margin:'0', padding:0, textAlign:'center', with:'100%'}}>
					<img src={data.img} style={{maxHeight:'100%',maxWidth:'100%',objectFit:'fill'}}/>
				</div>
				<label style={{cursor:'pointer', position:'absolute',right:10,top:18,zIndex:'9999'}}>
					<i className="material-icons black-text small edit-perfil-icon" style={{background:'rgba(255, 255, 255, .4)', borderRadius:'100%', padding:10, border:'solid 1px gray'}}>edit</i>
					<input type="file"  style={{display:'none'}} onChange={e=>_imgUpload(e.target.files[0])} accept="image/png, image/gif, image/jpeg"/>
				</label>
			</div>
			<div className="col m7 card-content" style={{margin:0, padding:0}}>
				<div className="row" style={{margin:0, padding:0}}>
					<div className="col m5" style={{margin:0, padding:0,}}>
						<div className="row" style={{margin:0,padding:'15px', width:'100%',}}>
							<div className="switch" style={{margin:'0', textAlign:'left'}}>
								<label style={{fontWeight:600,}}>
									Off Banner
									<input type="checkbox" checked={data.visible} onChange={(e)=>setData({...data, visible:e.target.checked})} style={{fontSize:'12px'}} />
									<span className="lever"></span>
									On Banner
								</label>
							</div> 
						</div>
						<div className="row" style={{margin:0,padding:'15px', width:'100%',}}>
							<div className="switch" style={{margin:'0', textAlign:'left'}}>
								<label style={{fontWeight:600,}}>
									Off Botón
									<input type="checkbox" checked={data.visible_btn} onChange={(e)=>setData({...data, visible_btn:e.target.checked})}/>
									<span className="lever"></span>
									On Botón
								</label>
							</div> 
							{data.visible_btn==1&&
							<><div className="input-field col s12" style={{}}>
								<label htmlFor="titulo">Titulo del Botón</label>
								<input  type="text" className="validate" value={data.titulo_btn||''} maxLength={70} onChange={(e)=>setData({...data, titulo_btn:e.target.value})} style={{fontSize:12,}} />
								{(data.titulo_btn!== undefined && data.titulo_btn!==null  && data.titulo_btn.length >= 15)&&
								<p style={{fontSize:12, fontWeight:600}} className="red-text">Limite de caracteres 15</p>
								}
							</div>
							<div className="input-field col s12" style={{}}>
								<label htmlFor="titulo">URL del Botón</label>
								<input  type="text" className="validate" value={data.url_btn||''} maxLength={70} onChange={(e)=>setData({...data, url_btn:e.target.value})} style={{fontSize:12,}} />
								{(data.url_btn!== undefined && data.url_btn!==null  && data.url_btn.length >= 70)&&
									<p style={{fontSize:12, fontWeight:600}} className="red-text">Limite de caracteres 70</p>
								}
							</div>
							</>
							}
						</div>
					</div>
					<div className="col m7">
						<div className="input-field col s12"><label htmlFor="titulo">Color Textos<br /></label></div>
						<div className="input-field col s12">
							<input  type="color" className="validate" value={data.color_txt||''} maxLength={70} onChange={(e)=>setData({...data, color_txt:e.target.value})} />
						</div>
						<div className="input-field col s12" style={{}}>
							<label htmlFor="titulo">Titulo</label>
							<input  type="text" className="validate" value={data.titulo||''} maxLength={70} onChange={(e)=>setData({...data, titulo:e.target.value})} style={{fontSize:12}} />
							{(data.titulo!== undefined && data.titulo!==null  && data.titulo.length >= 70)&&
							<p style={{fontSize:12, fontWeight:600}} className="red-text">Limite de caracteres 70</p>
							}
						</div>
						<div className="input-field col s12">
							<label htmlFor="descripcion" className="inputCarrusel">Descripción</label>
							<textarea className="materialize-textarea" style={{fontSize:'14px', height:'56px', lineHeight:1}} maxLength={160} value={data.descripcion||''} onChange={(e)=>setData({...data, descripcion:e.target.value})}>
							</textarea>
							{(data.descripcion!== undefined &&  data.descripcion!==null && data.descripcion.length >=160)&&
							<p style={{fontSize:12, fontWeight:600}} className="red-text">Limite de caracteres 160</p>
							}
						</div>
					</div>
				</div>
			</div>
			<div className="col m1 card-action" style={{textAlign:'right', margin:'0', padding:0, textAlign:'center',}}>
				<div className="input-field col s12" style={{margin:'10px 0', padding:0,}}>
					<label htmlFor="titulo" style={{margin:'0', padding:0,}}>Guardar <br></br> </label>
				</div>
				<div className="input-field col s12" style={{margin:'10px 0', padding:0,}}>
					<a className="waves-effect waves-light btn s12" style={{margin:'0 auto', textAlign:'center',  padding:'0 12px 10px 12px', borderRadius:'6px'}} onClick={_updateImageData}>
						<i className="material-icons right" style={{margin:'0 auto', textAlign:'center', }}>save</i>
					</a>
				</div>
				<div className="input-field col s12" style={{margin:'10px 0', padding:0,}}>
					<a style={{zIndex:'999'}} className="waves-effect waves-light btn s12 red" onClick={_deleteImg} style={{margin:'0 auto', textAlign:'center',  padding:'0 12px 10px 12px', borderRadius:'6px'}}>
						<i className="material-icons right" style={{margin:'0 auto', textAlign:'center', }}>delete</i>
					</a>
				</div>
			</div>
		</div>
	);
};

export default SliderImg;
