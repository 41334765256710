import React, { useEffect } from 'react';
import './App.scss';
import 'materialize-css';
import 'materialize-css/sass/materialize.scss';
import Login from './components/auth/login'
import Dashboard from './components/dashboard'
import Create from './components/auth/signup'


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {UserProvider} from './UserContext';

const App=()=>{

	useEffect(()=>{
		document.head.innerHTML +=  `<link rel="shortcut icon" href="${window.axios.defaults.baseURL}/logo.png" />`;
		document.body.style.background = `url("${window.axios.defaults.baseURL}/logo.png") no-repeat fixed center`;
		document.body.style.backgroundSize='300px'
	},[])

	return (
		<UserProvider>
		<Router scrollRestoration="auto">
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/create" element={<Create />} />
				<Route path="/*" element={<Dashboard />} />
			</Routes>
		</Router>
		</UserProvider>
	);
}

export default App;
