import React, { useState, useContext } from 'react' 
import { axios } from '../../axios'
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from '../../UserContext';
import Loader from '../loading'


const Create=props=>{

	const [data,setData] = useState({})
	const { setUser, setAuth } = useContext(UserContext);
	const [loader, setLoader] = useState(false)
	const navigate = useNavigate()

	const _create=(e)=>{

		e.preventDefault();

		setLoader(true)
		axios.post('/api/signup_seller',data)
		.then(r=>{
			setUser(r.data.user)
			localStorage.setItem('access_token', 'Bearer '+r.data.access_token);
			setAuth(true)
			setLoader(false)
			navigate('/')
		}).catch(e=>{
			alert('el usuario no se pudo crear con exito por favor verifique sus datos')
		})
	}

	if(loader)
		return <Loader />
			
	return (
		<div className="row center">
			<div className="col s6" style={{height: '100px',position: 'absolute',top: '20%',left: '40%',margin: '-70px 0 0 -170px'}}>
				<div className="card">
					<div className="card-content black-text">
						<span className="card-title store-name"></span>
						<span className="card-title">Cuenta Seller</span>
						<div className="row">
							<form onSubmit={_create} className="col s12">
								<div className="row">
									<div className="input-field col s12">
										<i className="material-icons prefix">account_circle</i>
										<input onChange={(e)=>setData({...data,name:e.target.value})} value={data.name} id="name" type="text" className="validate"/>
										<label htmlFor="name">Nombre</label>
									</div>
									<div className="input-field col s12">
										<i className="material-icons prefix">email</i>
										<input onChange={(e)=>setData({...data,email:e.target.value})} value={data.email} id="email" type="text" className="validate"/>
										<label htmlFor="email">Correo</label>
									</div>
									<div className="input-field col s12">
										<i className="material-icons prefix">lock</i>
										<input onChange={(e)=>setData({...data,password:e.target.value})} value={data.password} id="password" type="password" className="validate"/>
										<label htmlFor="password">Contraseña</label>
									</div>
									<div className="input-field col s12">
										<i className="material-icons prefix">lock</i>
										<input onChange={(e)=>setData({...data,password_confirmation:e.target.value})} value={data.password_confirmation} id="password_confirmation" type="password" className="validate"/>
										<label htmlFor="password_confirmation">Confirmar Contraseña</label>
									</div>
								</div>
								<button onClick={_create} className="btn waves-effect waves-light grey lighten-1" type="submit" name="action">Crear Cuenta<i className="material-icons right">send</i></button>
							</form>
						</div>
					</div>
					<div className="card-action">
						<Link to="/" className='black-text'>Ya tienes cuenta? inicia sesion aquí</Link>
					</div>
				</div>
			</div>
		</div>
		)
}
export default Create
