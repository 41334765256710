/**
  * @params time_string [String]
  * @return minutesDifference [Number]
  */
export const minDiff = (dateString) => {

	const pastTime = new Date(dateString);
	const currentTime = new Date();

	const timeDifferenceMs = currentTime - pastTime;
	const minutesDifference = Math.floor(timeDifferenceMs / 60000);
	return minutesDifference
}
