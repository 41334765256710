import React, { useEffect } from 'react'
import { axios } from '../../axios';
import Chart from 'chart.js/auto';

const Visitas = () => {

	useEffect(()=>{
		axios.get('/api/seller/visita/company_profile')
		.then(r => {
			console.log(r.data)

			/**
			  *
			  * CHART
			  */

			/**
			  * barchart
			  */
			const ctx = document.getElementById('barchart');
			new Chart(ctx, {
				type: 'bar',
				data: {
					labels: r.data.map(r=>r.fecha),
					datasets: [{
						label: '# Visits',
						data: r.data.map(r=>r.visitas),
						borderWidth: 1,
						backgroundColor: '#BE1D2C',
					}]
				},
				 options: {
					responsive: true,
					plugins: {
						legend: {
							position: 'top',
						},
						title: {
							display: true,
							text: 'Bar Chart'
						}
					}
				},
			});

			/**
			  * linechart
			  */
			const ctx2 = document.getElementById('linechart');
			new Chart(ctx2, {
				type: 'line',
				data: {
					labels: r.data.map(r=>r.fecha),
					datasets: [{
						label: '# Visits',
						data: r.data.map(r=>r.visitas),
						borderWidth: 1,
						backgroundColor: '#BE1D2C',
					}]
				},
				 options: {
					responsive: true,
					plugins: {
						legend: {
							position: 'top',
						},
						title: {
							display: true,
							text: 'Line Chart'
						}
					}
				},
			});
		})
		.catch(e=>alert(e))

	}, [])

	return(
		<div className='card-panel'>
			<h4>Visits</h4>
			<div>
				<canvas id="barchart"></canvas>
				<canvas id="linechart"></canvas>
			</div>
		</div>
	)
}

export default Visitas
